var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
import { __assign } from "tslib";
import { includes } from 'lodash';
import { SECTION_CATEGORY, SECTION_HOMEPAGE, SECTION_MY_POSTS, SECTION_POST_LIST, SECTION_RELATED_POSTS, } from './sections';
export var LAYOUT_CARD_SMALL = 0;
export var LAYOUT_CARD_MEDIUM = 1;
export var LAYOUT_CARD_LARGE = 2;
export var LAYOUT_CARD_PROFILE = 3;
export var LAYOUT_FULL_POST = 4;
export var LAYOUT_MOBILE = 5;
export var LAYOUT_SIDE_BY_SIDE = 6;
export var LAYOUT_GRID = 7;
export var LAYOUT_TEXT_ON_IMAGE_SMALL = 8;
export var LAYOUT_TEXT_ON_IMAGE_MEDIUM = 9;
export var LAYOUT_ONE_COLUMN_MOBILE = 10;
export var LAYOUT_SIDE_BY_SIDE_MOBILE = 11;
export var LAYOUT_TEXT_ON_IMAGE_MOBILE = 12;
export var LAYOUT_PG_TEXT_ON_IMAGE_SMALL = 23;
export var LAYOUT_PG_TEXT_ON_IMAGE_MEDIUM = 13;
export var LAYOUT_PG_TEXT_ON_IMAGE_LARGE = 24;
export var LAYOUT_PG_CARD_MEDIUM = 14;
export var LAYOUT_PG_SIDE_BY_SIDE = 15;
export var LAYOUT_PG_SIDE_BY_SIDE_RIGHT = 22;
export var LAYOUT_PG_GRID = 16;
export var LAYOUT_PG_GRID_INTERMEDIATE = 20;
export var LAYOUT_PG_GRID_LARGE = 21;
export var LAYOUT_PG_ONE_COLUMN = 17;
export var LAYOUT_SLIDER = 18;
export var LAYOUT_LIST = 19;
export var LAYOUT_LIST_MEDIUM = 25;
export var LAYOUT_LIST_LARGE = 26;
var OLD_LAYOUT_PG_MAP = (_a = {},
    _a[LAYOUT_CARD_MEDIUM] = LAYOUT_PG_CARD_MEDIUM,
    _a[LAYOUT_SIDE_BY_SIDE] = LAYOUT_PG_SIDE_BY_SIDE,
    _a[LAYOUT_TEXT_ON_IMAGE_MEDIUM] = LAYOUT_PG_TEXT_ON_IMAGE_MEDIUM,
    _a[LAYOUT_GRID] = LAYOUT_PG_GRID,
    _a[LAYOUT_CARD_SMALL] = LAYOUT_PG_CARD_MEDIUM,
    _a[LAYOUT_TEXT_ON_IMAGE_SMALL] = LAYOUT_PG_TEXT_ON_IMAGE_MEDIUM,
    _a[LAYOUT_CARD_LARGE] = LAYOUT_PG_GRID,
    _a);
export var getPGMappedLayout = function (layoutType) {
    if (isLayoutOneColumn(layoutType)) {
        return LAYOUT_PG_ONE_COLUMN;
    }
    return OLD_LAYOUT_PG_MAP[layoutType] || layoutType;
};
export var LAYOUT_NAMES_BY_ID = (_b = {},
    _b[LAYOUT_CARD_SMALL] = 'Brick',
    _b[LAYOUT_CARD_MEDIUM] = 'Tiled',
    _b[LAYOUT_CARD_LARGE] = 'One column',
    _b[LAYOUT_FULL_POST] = 'Full post',
    _b[LAYOUT_SIDE_BY_SIDE] = 'Side by side',
    _b[LAYOUT_GRID] = 'Editorial',
    _b[LAYOUT_TEXT_ON_IMAGE_SMALL] = 'Postcard',
    _b[LAYOUT_TEXT_ON_IMAGE_MEDIUM] = 'Magazine',
    _b[LAYOUT_ONE_COLUMN_MOBILE] = 'Mobile One Column',
    _b[LAYOUT_SIDE_BY_SIDE_MOBILE] = 'Mobile Side By Side',
    _b[LAYOUT_TEXT_ON_IMAGE_MOBILE] = 'Mobile Magazine',
    _b[LAYOUT_PG_TEXT_ON_IMAGE_SMALL] = 'Magazine Pro Gallery (small)',
    _b[LAYOUT_PG_TEXT_ON_IMAGE_MEDIUM] = 'Magazine Pro Gallery',
    _b[LAYOUT_PG_TEXT_ON_IMAGE_LARGE] = 'Magazine Pro Gallery (large)',
    _b[LAYOUT_PG_CARD_MEDIUM] = 'Tiled Pro Gallery',
    _b[LAYOUT_PG_SIDE_BY_SIDE] = 'Side by side Pro Gallery',
    _b[LAYOUT_PG_SIDE_BY_SIDE_RIGHT] = 'Side by side (right) Pro Gallery',
    _b[LAYOUT_PG_GRID] = 'Editorial Pro Gallery',
    _b[LAYOUT_PG_GRID_INTERMEDIATE] = 'Editorial Pro Gallery (medium)',
    _b[LAYOUT_PG_GRID_LARGE] = 'Editorial Pro Gallery (large)',
    _b[LAYOUT_PG_ONE_COLUMN] = 'One column Pro Gallery',
    _b[LAYOUT_SLIDER] = 'Slider',
    _b[LAYOUT_LIST] = 'List',
    _b[LAYOUT_LIST_MEDIUM] = 'List Medium',
    _b[LAYOUT_LIST_LARGE] = 'List Large',
    _b);
export var getLayoutNameById = function (layoutId) { return LAYOUT_NAMES_BY_ID[layoutId]; };
export var PAGINATION_TYPE_ON_SCROLL_DOWN = 0;
export var PAGINATION_TYPE_WITH_PAGE_NUMBERS = 1;
export var PAGINATION_TYPE_NAME_BY_ID = (_c = {},
    _c[PAGINATION_TYPE_ON_SCROLL_DOWN] = 'On scroll down',
    _c[PAGINATION_TYPE_WITH_PAGE_NUMBERS] = 'With page numbers',
    _c);
export var getLayoutPaginationTypeNameById = function (typeId) {
    return PAGINATION_TYPE_NAME_BY_ID[typeId];
};
export var SEO_LAYOUT_TYPE_FALLBACK = LAYOUT_GRID;
export var LAYOUT_NAME_CARD_SMALL = 'small';
export var LAYOUT_NAME_CARD_MEDIUM = 'medium';
export var LAYOUT_NAME_CARD_LARGE = 'large';
export var LAYOUT_NAME_CARD_PROFILE = 'profile';
export var LAYOUT_NAME_FULL_POST = 'full';
export var LAYOUT_NAME_MOBILE = 'mobile';
export var LAYOUT_NAME_SIDE_BY_SIDE = 'side-by-side';
export var LAYOUT_NAME_GRID = 'grid';
export var LAYOUT_NAME_TEXT_ON_IMAGE_SMALL = 'text-on-image-small';
export var LAYOUT_NAME_TEXT_ON_IMAGE_MEDIUM = 'text-on-image-medium';
export var LAYOUT_NAME_ONE_COLUMN_MOBILE = 'one-column-mobile';
export var LAYOUT_NAME_SIDE_BY_SIDE_MOBILE = 'side-by-side-mobile';
export var LAYOUT_NAME_TEXT_ON_IMAGE_MOBILE = 'text-on-image-mobile';
export var LAYOUT_NAME_PG_TEXT_ON_IMAGE_SMALL = 'pg-text-on-image-small';
export var LAYOUT_NAME_PG_TEXT_ON_IMAGE_MEDIUM = 'pg-text-on-image-medium';
export var LAYOUT_NAME_PG_TEXT_ON_IMAGE_LARGE = 'pg-text-on-image-large';
export var LAYOUT_NAME_PG_CARD_MEDIUM = 'pg-medium';
export var LAYOUT_NAME_PG_SIDE_BY_SIDE = 'pg-side-by-side';
export var LAYOUT_NAME_PG_SIDE_BY_SIDE_RIGHT = 'pg-side-by-side-right';
export var LAYOUT_NAME_PG_GRID = 'pg-grid';
export var LAYOUT_NAME_PG_GRID_INTERMEDIATE = 'pg-grid-intermediate';
export var LAYOUT_NAME_PG_GRID_LARGE = 'pg-grid-large';
export var LAYOUT_NAME_PG_ONE_COLUMN = 'pg-one-column';
export var LAYOUT_NAME_SLIDER = 'slider';
export var LAYOUT_NAME_LIST = 'list';
export var LAYOUT_NAME_LIST_MEDIUM = 'list-medium';
export var LAYOUT_NAME_LIST_LARGE = 'list-large';
export var MOBILE_LAYOUT_NAMES = [
    LAYOUT_NAME_MOBILE,
    LAYOUT_NAME_ONE_COLUMN_MOBILE,
    LAYOUT_NAME_SIDE_BY_SIDE_MOBILE,
    LAYOUT_NAME_TEXT_ON_IMAGE_MOBILE,
];
export var FEED_LAYOUT_TYPES = (_d = {},
    _d[LAYOUT_CARD_SMALL] = LAYOUT_NAME_CARD_SMALL,
    _d[LAYOUT_CARD_MEDIUM] = LAYOUT_NAME_CARD_MEDIUM,
    _d[LAYOUT_CARD_LARGE] = LAYOUT_NAME_CARD_LARGE,
    _d[LAYOUT_FULL_POST] = LAYOUT_NAME_FULL_POST,
    _d[LAYOUT_SIDE_BY_SIDE] = LAYOUT_NAME_SIDE_BY_SIDE,
    _d[LAYOUT_GRID] = LAYOUT_NAME_GRID,
    _d[LAYOUT_TEXT_ON_IMAGE_SMALL] = LAYOUT_NAME_TEXT_ON_IMAGE_SMALL,
    _d[LAYOUT_TEXT_ON_IMAGE_MEDIUM] = LAYOUT_NAME_TEXT_ON_IMAGE_MEDIUM,
    _d[LAYOUT_TEXT_ON_IMAGE_MOBILE] = LAYOUT_NAME_TEXT_ON_IMAGE_MOBILE,
    _d[LAYOUT_PG_TEXT_ON_IMAGE_SMALL] = LAYOUT_NAME_PG_TEXT_ON_IMAGE_SMALL,
    _d[LAYOUT_PG_TEXT_ON_IMAGE_MEDIUM] = LAYOUT_NAME_PG_TEXT_ON_IMAGE_MEDIUM,
    _d[LAYOUT_PG_TEXT_ON_IMAGE_LARGE] = LAYOUT_NAME_PG_TEXT_ON_IMAGE_LARGE,
    _d[LAYOUT_PG_CARD_MEDIUM] = LAYOUT_NAME_PG_CARD_MEDIUM,
    _d[LAYOUT_PG_SIDE_BY_SIDE] = LAYOUT_NAME_PG_SIDE_BY_SIDE,
    _d[LAYOUT_PG_SIDE_BY_SIDE_RIGHT] = LAYOUT_NAME_PG_SIDE_BY_SIDE_RIGHT,
    _d[LAYOUT_PG_GRID] = LAYOUT_NAME_PG_GRID,
    _d[LAYOUT_PG_GRID_INTERMEDIATE] = LAYOUT_NAME_PG_GRID_INTERMEDIATE,
    _d[LAYOUT_PG_GRID_LARGE] = LAYOUT_NAME_PG_GRID_LARGE,
    _d[LAYOUT_PG_ONE_COLUMN] = LAYOUT_NAME_PG_ONE_COLUMN,
    _d[LAYOUT_LIST] = LAYOUT_NAME_LIST,
    _d[LAYOUT_LIST_MEDIUM] = LAYOUT_NAME_LIST_MEDIUM,
    _d[LAYOUT_LIST_LARGE] = LAYOUT_NAME_LIST_LARGE,
    _d[LAYOUT_SLIDER] = LAYOUT_NAME_SLIDER,
    _d);
export var POST_LIST_LAYOUT_TYPES = (_e = {},
    _e[LAYOUT_PG_GRID] = LAYOUT_NAME_PG_GRID,
    _e[LAYOUT_PG_GRID_INTERMEDIATE] = LAYOUT_NAME_PG_GRID_INTERMEDIATE,
    _e[LAYOUT_PG_GRID_LARGE] = LAYOUT_NAME_PG_GRID_LARGE,
    _e[LAYOUT_SLIDER] = LAYOUT_NAME_SLIDER,
    _e[LAYOUT_LIST] = LAYOUT_NAME_LIST,
    _e[LAYOUT_LIST_MEDIUM] = LAYOUT_NAME_LIST_MEDIUM,
    _e[LAYOUT_LIST_LARGE] = LAYOUT_NAME_LIST_LARGE,
    _e[LAYOUT_PG_SIDE_BY_SIDE] = LAYOUT_NAME_PG_SIDE_BY_SIDE,
    _e[LAYOUT_PG_SIDE_BY_SIDE_RIGHT] = LAYOUT_NAME_PG_SIDE_BY_SIDE_RIGHT,
    _e[LAYOUT_PG_ONE_COLUMN] = LAYOUT_NAME_PG_ONE_COLUMN,
    _e[LAYOUT_PG_TEXT_ON_IMAGE_SMALL] = LAYOUT_NAME_PG_TEXT_ON_IMAGE_SMALL,
    _e[LAYOUT_PG_TEXT_ON_IMAGE_MEDIUM] = LAYOUT_NAME_PG_TEXT_ON_IMAGE_MEDIUM,
    _e[LAYOUT_PG_TEXT_ON_IMAGE_LARGE] = LAYOUT_NAME_PG_TEXT_ON_IMAGE_LARGE,
    _e[LAYOUT_PG_GRID_INTERMEDIATE] = LAYOUT_NAME_PG_GRID_INTERMEDIATE,
    _e[LAYOUT_LIST] = LAYOUT_NAME_LIST,
    _e[LAYOUT_PG_CARD_MEDIUM] = LAYOUT_NAME_PG_CARD_MEDIUM,
    _e);
export var RELATED_POSTS_LAYOUT_TYPES = (_f = {},
    _f[LAYOUT_PG_GRID] = LAYOUT_NAME_PG_GRID,
    _f[LAYOUT_PG_GRID_INTERMEDIATE] = LAYOUT_NAME_PG_GRID_INTERMEDIATE,
    _f[LAYOUT_PG_GRID_LARGE] = LAYOUT_NAME_PG_GRID_LARGE,
    _f[LAYOUT_SLIDER] = LAYOUT_NAME_SLIDER,
    _f[LAYOUT_LIST] = LAYOUT_NAME_LIST,
    _f[LAYOUT_LIST_MEDIUM] = LAYOUT_NAME_LIST_MEDIUM,
    _f[LAYOUT_LIST_LARGE] = LAYOUT_NAME_LIST_LARGE,
    _f[LAYOUT_PG_SIDE_BY_SIDE] = LAYOUT_NAME_PG_SIDE_BY_SIDE,
    _f[LAYOUT_PG_SIDE_BY_SIDE_RIGHT] = LAYOUT_NAME_PG_SIDE_BY_SIDE_RIGHT,
    _f[LAYOUT_PG_ONE_COLUMN] = LAYOUT_NAME_PG_ONE_COLUMN,
    _f[LAYOUT_PG_TEXT_ON_IMAGE_SMALL] = LAYOUT_NAME_PG_TEXT_ON_IMAGE_SMALL,
    _f[LAYOUT_PG_TEXT_ON_IMAGE_MEDIUM] = LAYOUT_NAME_PG_TEXT_ON_IMAGE_MEDIUM,
    _f[LAYOUT_PG_TEXT_ON_IMAGE_LARGE] = LAYOUT_NAME_PG_TEXT_ON_IMAGE_LARGE,
    _f[LAYOUT_PG_GRID_INTERMEDIATE] = LAYOUT_NAME_PG_GRID_INTERMEDIATE,
    _f[LAYOUT_LIST] = LAYOUT_NAME_LIST,
    _f[LAYOUT_PG_CARD_MEDIUM] = LAYOUT_NAME_PG_CARD_MEDIUM,
    _f);
export var DESKTOP_LAYOUT_TYPES_TO_MOBILE = (_g = {},
    _g[LAYOUT_PG_GRID] = LAYOUT_PG_GRID,
    _g[LAYOUT_PG_GRID_INTERMEDIATE] = LAYOUT_PG_GRID,
    _g[LAYOUT_PG_GRID_LARGE] = LAYOUT_PG_GRID,
    _g[LAYOUT_SLIDER] = LAYOUT_SLIDER,
    _g[LAYOUT_LIST] = LAYOUT_LIST,
    _g[LAYOUT_LIST_MEDIUM] = LAYOUT_LIST,
    _g[LAYOUT_LIST_LARGE] = LAYOUT_LIST,
    _g[LAYOUT_PG_SIDE_BY_SIDE] = LAYOUT_PG_GRID,
    _g[LAYOUT_PG_SIDE_BY_SIDE_RIGHT] = LAYOUT_PG_GRID,
    _g[LAYOUT_PG_TEXT_ON_IMAGE_MEDIUM] = LAYOUT_PG_TEXT_ON_IMAGE_MEDIUM,
    _g[LAYOUT_PG_TEXT_ON_IMAGE_SMALL] = LAYOUT_PG_TEXT_ON_IMAGE_MEDIUM,
    _g[LAYOUT_PG_TEXT_ON_IMAGE_LARGE] = LAYOUT_PG_TEXT_ON_IMAGE_MEDIUM,
    _g[LAYOUT_PG_ONE_COLUMN] = LAYOUT_PG_GRID,
    _g[LAYOUT_PG_CARD_MEDIUM] = LAYOUT_PG_GRID,
    _g);
export var MY_POSTS_LAYOUT_TYPES = (_h = {},
    _h[LAYOUT_PG_SIDE_BY_SIDE] = LAYOUT_NAME_PG_SIDE_BY_SIDE,
    _h);
export var LAYOUT_TYPES = __assign(__assign(__assign(__assign({}, FEED_LAYOUT_TYPES), POST_LIST_LAYOUT_TYPES), RELATED_POSTS_LAYOUT_TYPES), MY_POSTS_LAYOUT_TYPES);
export var LAYOUT_TYPES_BY_SECTION = (_j = {},
    _j[SECTION_HOMEPAGE] = FEED_LAYOUT_TYPES,
    _j[SECTION_CATEGORY] = FEED_LAYOUT_TYPES,
    _j[SECTION_POST_LIST] = POST_LIST_LAYOUT_TYPES,
    _j[SECTION_RELATED_POSTS] = RELATED_POSTS_LAYOUT_TYPES,
    _j[SECTION_MY_POSTS] = MY_POSTS_LAYOUT_TYPES,
    _j);
export var getLayoutTypeById = function (layoutId) { return LAYOUT_TYPES[layoutId]; };
export var LAYOUT_TYPES_MOBILE = (_k = {},
    _k[LAYOUT_ONE_COLUMN_MOBILE] = LAYOUT_NAME_ONE_COLUMN_MOBILE,
    _k[LAYOUT_SIDE_BY_SIDE_MOBILE] = LAYOUT_NAME_SIDE_BY_SIDE_MOBILE,
    _k[LAYOUT_TEXT_ON_IMAGE_MOBILE] = LAYOUT_NAME_TEXT_ON_IMAGE_MOBILE,
    _k);
export var OTHER_LAYOUT_TYPES = (_l = {},
    _l[LAYOUT_CARD_PROFILE] = LAYOUT_NAME_CARD_PROFILE,
    _l[LAYOUT_MOBILE] = LAYOUT_NAME_MOBILE,
    _l);
export var getLayoutName = function (layoutType) {
    return LAYOUT_TYPES[layoutType] ||
        LAYOUT_TYPES_MOBILE[layoutType] ||
        OTHER_LAYOUT_TYPES[layoutType];
};
export var isLayoutProGallery = function (layoutType) {
    return [
        LAYOUT_PG_TEXT_ON_IMAGE_SMALL,
        LAYOUT_PG_TEXT_ON_IMAGE_MEDIUM,
        LAYOUT_PG_TEXT_ON_IMAGE_LARGE,
        LAYOUT_PG_CARD_MEDIUM,
        LAYOUT_PG_SIDE_BY_SIDE,
        LAYOUT_PG_SIDE_BY_SIDE_RIGHT,
        LAYOUT_PG_GRID,
        LAYOUT_PG_GRID_INTERMEDIATE,
        LAYOUT_PG_GRID_LARGE,
        LAYOUT_PG_ONE_COLUMN,
        LAYOUT_LIST,
        LAYOUT_LIST_MEDIUM,
        LAYOUT_LIST_LARGE,
        LAYOUT_SLIDER,
    ].includes(Number(layoutType));
};
export var isPGLayoutWithDescription = function (layoutType) {
    return [
        LAYOUT_PG_CARD_MEDIUM,
        LAYOUT_PG_SIDE_BY_SIDE,
        LAYOUT_PG_SIDE_BY_SIDE_RIGHT,
        LAYOUT_PG_GRID,
        LAYOUT_PG_GRID_INTERMEDIATE,
        LAYOUT_PG_GRID_LARGE,
        LAYOUT_PG_ONE_COLUMN,
        LAYOUT_SLIDER,
    ].includes(Number(layoutType));
};
export var isLayoutNameOneOfProGallery = function (layoutName) {
    return [
        LAYOUT_NAME_PG_TEXT_ON_IMAGE_SMALL,
        LAYOUT_NAME_PG_TEXT_ON_IMAGE_MEDIUM,
        LAYOUT_NAME_PG_TEXT_ON_IMAGE_LARGE,
        LAYOUT_NAME_PG_CARD_MEDIUM,
        LAYOUT_NAME_PG_SIDE_BY_SIDE,
        LAYOUT_NAME_PG_SIDE_BY_SIDE_RIGHT,
        LAYOUT_NAME_PG_GRID,
        LAYOUT_NAME_PG_ONE_COLUMN,
    ].includes(layoutName);
};
export var PG_LAYOUTS_WITH_CONTENT_HEIGHT = [
    LAYOUT_PG_GRID,
    LAYOUT_PG_GRID_INTERMEDIATE,
    LAYOUT_PG_GRID_LARGE,
    LAYOUT_PG_CARD_MEDIUM,
    LAYOUT_PG_ONE_COLUMN,
    LAYOUT_SLIDER,
    LAYOUT_LIST,
    LAYOUT_LIST_MEDIUM,
    LAYOUT_LIST_LARGE,
];
var isLayoutOneColumn = function (layoutType) {
    return Number(layoutType) === LAYOUT_CARD_LARGE;
};
export var isLayoutPGOneColumn = function (layoutType) {
    return Number(layoutType) === LAYOUT_PG_ONE_COLUMN;
};
export var isLayoutPGGrid = function (layoutType) {
    return Number(layoutType) === LAYOUT_PG_GRID ||
        Number(layoutType) === LAYOUT_PG_GRID_INTERMEDIATE ||
        Number(layoutType) === LAYOUT_PG_GRID_LARGE;
};
export var isLayoutPGSideBySide = function (layoutType) {
    return Number(layoutType) === LAYOUT_PG_SIDE_BY_SIDE ||
        Number(layoutType) === LAYOUT_PG_SIDE_BY_SIDE_RIGHT;
};
export var isLayoutPGCardMedium = function (layoutType) {
    return Number(layoutType) === LAYOUT_PG_CARD_MEDIUM;
};
export var isLayoutPGCard = function (layoutType) {
    return Number(layoutType) === LAYOUT_PG_CARD_MEDIUM;
};
export var isLayoutPGWithContentHeight = function (layoutType) {
    return includes(PG_LAYOUTS_WITH_CONTENT_HEIGHT, Number(layoutType));
};
export var isLayoutGrid = function (layoutType) { return Number(layoutType) === LAYOUT_GRID; };
export var isLayoutFullPost = function (layoutType) {
    return Number(layoutType) === LAYOUT_FULL_POST;
};
export var isLayoutTextOnImage = function (layoutType) {
    return includes([
        LAYOUT_TEXT_ON_IMAGE_SMALL,
        LAYOUT_TEXT_ON_IMAGE_MEDIUM,
        LAYOUT_TEXT_ON_IMAGE_MOBILE,
        LAYOUT_PG_TEXT_ON_IMAGE_SMALL,
        LAYOUT_PG_TEXT_ON_IMAGE_MEDIUM,
        LAYOUT_PG_TEXT_ON_IMAGE_LARGE,
    ], Number(layoutType));
};
export var isLayoutSlider = function (layoutType) {
    return Number(layoutType) === LAYOUT_SLIDER;
};
export var isLayoutList = function (layoutType) {
    return Number(layoutType) === LAYOUT_LIST ||
        Number(layoutType) === LAYOUT_LIST_MEDIUM ||
        Number(layoutType) === LAYOUT_LIST_LARGE;
};
export var isLayoutMobile = function (layoutType) {
    return includes([
        LAYOUT_MOBILE,
        LAYOUT_ONE_COLUMN_MOBILE,
        LAYOUT_SIDE_BY_SIDE_MOBILE,
        LAYOUT_TEXT_ON_IMAGE_MOBILE,
    ], Number(layoutType));
};
export var isLayoutNameMobile = function (layoutName) {
    return includes(MOBILE_LAYOUT_NAMES, layoutName);
};
export var isLayoutNamePGSideBySide = function (layoutName) {
    return includes([LAYOUT_NAME_PG_SIDE_BY_SIDE, LAYOUT_NAME_PG_SIDE_BY_SIDE_RIGHT], layoutName);
};
export var isLayoutNamePGOneColumn = function (layoutName) {
    return layoutName === LAYOUT_NAME_PG_ONE_COLUMN;
};
export var isLayoutNameGrid = function (layoutName) { return layoutName === LAYOUT_NAME_GRID; };
export var isLayoutNameSlider = function (layoutName) {
    return layoutName === LAYOUT_NAME_SLIDER;
};
export var isLayoutNameList = function (layoutName) {
    return includes([LAYOUT_NAME_LIST, LAYOUT_NAME_LIST_MEDIUM, LAYOUT_NAME_LIST_LARGE], layoutName);
};
export var isLayoutNameTextOnImage = function (layoutName) {
    return includes([
        LAYOUT_NAME_TEXT_ON_IMAGE_SMALL,
        LAYOUT_NAME_TEXT_ON_IMAGE_MEDIUM,
        LAYOUT_NAME_TEXT_ON_IMAGE_MOBILE,
        LAYOUT_NAME_PG_TEXT_ON_IMAGE_SMALL,
        LAYOUT_NAME_PG_TEXT_ON_IMAGE_MEDIUM,
        LAYOUT_NAME_PG_TEXT_ON_IMAGE_LARGE,
    ], layoutName);
};
var SUPPORTS_MULTIPLE_PAGES_PAGINATION = [
    LAYOUT_CARD_LARGE,
    LAYOUT_FULL_POST,
    LAYOUT_GRID,
    LAYOUT_PG_GRID_INTERMEDIATE,
    LAYOUT_PG_GRID_LARGE,
    LAYOUT_MOBILE,
    LAYOUT_SIDE_BY_SIDE,
    LAYOUT_TEXT_ON_IMAGE_MEDIUM,
    LAYOUT_TEXT_ON_IMAGE_SMALL,
    LAYOUT_ONE_COLUMN_MOBILE,
    LAYOUT_SIDE_BY_SIDE_MOBILE,
    LAYOUT_TEXT_ON_IMAGE_MOBILE,
    LAYOUT_PG_TEXT_ON_IMAGE_SMALL,
    LAYOUT_PG_TEXT_ON_IMAGE_MEDIUM,
    LAYOUT_PG_TEXT_ON_IMAGE_LARGE,
    LAYOUT_PG_SIDE_BY_SIDE,
    LAYOUT_PG_SIDE_BY_SIDE_RIGHT,
    LAYOUT_PG_GRID,
    LAYOUT_PG_ONE_COLUMN,
    LAYOUT_LIST,
    LAYOUT_LIST_MEDIUM,
    LAYOUT_LIST_LARGE,
];
export var isMultiplePagesSupported = function (layoutType) {
    return includes(SUPPORTS_MULTIPLE_PAGES_PAGINATION, layoutType);
};
export var isAuthorImageSupported = function (layoutId) {
    if ([
        LAYOUT_TEXT_ON_IMAGE_SMALL,
        LAYOUT_TEXT_ON_IMAGE_MEDIUM,
        LAYOUT_PG_TEXT_ON_IMAGE_SMALL,
        LAYOUT_PG_TEXT_ON_IMAGE_MEDIUM,
        LAYOUT_PG_TEXT_ON_IMAGE_LARGE,
    ].includes(layoutId)) {
        return false;
    }
    return true;
};
export var SIDE_BY_SIDE_LAYOUT_IMAGE_LEFT = 0;
export var SIDE_BY_SIDE_LAYOUT_IMAGE_RIGHT = 1;
export var SIDE_BY_SIDE_LAYOUT_IMAGE_NONE = 2;
